import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import GameRoom from "../gameRoom";
import "../../App.css";
import PromptPage from "../PromptPage/PromptPage";
import { useParams } from "react-router-dom";

import { useIdleTimer } from "react-idle-timer/dist/index.legacy.cjs.js";
import AutokickModal from "../modals/AutokickModal";
import { useAtom } from "jotai";
import { userNameAtom } from "../../atoms/username.atom";
import { roominfoAtom } from "../../atoms/roominfo.atom";
import { SocketContext } from "../../SocketProvider";
import { getRandomWords } from "../words";
import { timerAtom } from "../../atoms/timer.atom";

const INITIAL_COUNTDOWN = 30;
const fiveMinutes = 5 * 60;
const IDLE_TIME_IN_SECONDS = fiveMinutes;

export default function RoomPage() {
  const socket = useContext(SocketContext);
  const [userName, setUserName] = useAtom(userNameAtom);
  const timerAtomState = useAtom(timerAtom);
  const [loading, setLoading] = useState(true);
  const [roomInfo, setRoomInfo] = useAtom(roominfoAtom);
  const { id: roomID } = useParams();
  const [isRoomFull, setIsRoomFull] = useState(false);
  const [isRoomNull, setIsRoomNull] = useState(false);
  const [state, setState] = useState("Active");
  const [idleCountDown, setIdleCountDown] = useState(INITIAL_COUNTDOWN);
  const [intervaler, setIntervaler] = useState(null);
  const [autoLeave, setAutoLeave] = useState(false);

  const setCurrentRoom = useCallback((room) => {
    if (!room) return;
    if (room?.roomID !== roomID) return;

    // if (roomInfo[room.roomID] === undefined) {
    setRoomInfo({ ...roomInfo, [room.roomID]: room });
    // }
    setLoading(false);
  }, []);

  const onComponentMounted = useCallback((userName) => {

    if (roomID === undefined || !userName) {
      return;
    }

    const data = { roomID: roomID, userName: userName };
    socket.emit("join-room", data);
    socket.emit("get-room-info", data);

    socket.on("roomFull", () => {
      setIsRoomFull(true);
      setLoading(false);
    });

    socket.on("get-room-info-response", (room) => {
      if (!room) {
        // setLoading(false);
        setIsRoomNull(true);
        console.log({ isRoomNullTet: "test", isRoomNull })
        return
      }
      setCurrentRoom(room);
    });
    socket.on("set-room-info-response", (room) => {
      setCurrentRoom(room);
    });

    socket.on("on-room-updated-broadcasted", (room) => {
      setCurrentRoom(room);
    });

    socket.on("game-on-picking-words-broadcasted", (room) => {

      if (room.game.drawer === userName) {
        room.game["random_words"] = getRandomWords(3, room.game.wordsType);
        // socket.emit("start-picking-word", roomID);
      }
      setCurrentRoom(room);
    });

    socket.on("on-picking-words-timer-broadcasted", (data) => {
      if (data.roomID === roomID) {
        const setTimerAtom = timerAtomState[1];
        setTimerAtom(data["picking_words_timer"]);
      }
    });

    socket.on("on-drawing-timer-broadcasted", (data) => {
      if (data.roomID === roomID) {
        const setTimerAtom = timerAtomState[1];
        setTimerAtom(data["on_drawing_timer"]);
      }
    });

    socket.on("game-on-drawer-start-drawing-broadcasted", (room) => {
      if (room.roomID === roomID) {

        setCurrentRoom(room);
      }
    });

    socket.on("update-current-room-broadcasted", (room) => {

      if (room.roomID === roomID) {
        setCurrentRoom(room);
      }
    })

    socket.on("on-room-destroyed-broadcasted", (data) => {
      console.log("on-room-destroyed-broadcasted", data);
      if (data.roomID === roomID) {
        setAutoLeave(true);
      }
    })

    // socket.on("setRoomInfo", (data) => {
    //   if (data) {
    //     if (roomInfo[data.roomID] === undefined) {
    //       setRoomInfo({ ...roomInfo, [data.roomID]: data });
    //     }
    //     setLoading(false);
    //     setIsRoomNull(false);
    //   } else {
    //     setIsRoomNull(true);
    //     setLoading(false);
    //   }
    // });
    return () => {
      socket.off("get-room-info-response");
      socket.off("set-room-info-response");
      socket.off("on-room-updated-broadcasted");
      socket.off("game-on-picking-words-broadcasted");
      socket.off("on-picking-words-timer-broadcasted");
    };
  }, []);

  const stopIntervaler = useCallback(() => {
    if (intervaler) {
      setIdleCountDown(INITIAL_COUNTDOWN);
      clearInterval(intervaler);
    }
  }, [intervaler]);

  useEffect(() => {
    if (idleCountDown === 0) {
      stopIntervaler();
      setAutoLeave(true);
    }
  }, [idleCountDown, stopIntervaler]);

  const playIntervaler = useCallback(() => {
    const interval = setInterval(() => {
      setIdleCountDown((count) => {
        return count - 1;
      });
    }, 1000);
    setIntervaler(interval);
  }, []);

  const onIdle = () => {
    console.log("im on iddle state");
    if (state === "Active") {
      const currentState = "Idle";
      setState("Idle");
      playIntervaler(currentState);
    }
  };

  const onActive = () => {
    // setState('Active')
  };

  const onAction = () => {
    // setCount(count + 1)
  };

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: IDLE_TIME_IN_SECONDS * 1000,
    throttle: 500,
  });

  useEffect(() => {
    //on component mounted
    onComponentMounted(userName);
    return () => {
      // on component unmounted do
      clearInterval(intervaler);
    };
  }, [intervaler, onComponentMounted, userName]);

  return (
    <div>
      {(() => {
        if (isRoomNull) {
          return (
            <PromptPage info={"Room does not exist!"}></PromptPage>
          );
        }
        else if (loading) {
          return (
            <PromptPage info={"Loading..."}></PromptPage>
          );
        } else if (isRoomFull) {
          return (
            <PromptPage info={"The room is full!"}></PromptPage>
          );
        } else {
          return (
            <>
              <AutokickModal
                isOpen={state === "Idle"}
                onResumeCallback={() => setState("Active")}
                idleCountDown={idleCountDown}
                initialCountdown={INITIAL_COUNTDOWN}
              />
              {roomInfo && (
                <GameRoom
                  autoLeave={autoLeave}
                  socket={socket}
                  setState={setState}
                  idleCountDown={idleCountDown}
                  userName={userName}
                  state={state}
                  roomID={roomID}
                ></GameRoom>

              )}
            </>
          );
        }
      })()}
    </div>
  );
}
