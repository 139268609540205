import React, { createContext } from "react";
import { io } from "socket.io-client";
import PropTypes from "prop-types";
import { useAtom } from "jotai";
import { allroomsAtom } from "./atoms/allrooms.atom";
import { eventAtom } from "./atoms/event.atom";

const socket = io("wss://server.drawnguess.my.id");
export const SocketContext = createContext(socket);

const username = localStorage.getItem("userName");

if (username) {
  socket.emit("current_user", { userName: username.replace(/^"(.+(?="$))"$/, '$1') });
}

export const SocketProvider = (props) => {
  const roomsAtom = useAtom(allroomsAtom);
  const eventAtomState = useAtom(eventAtom);

  React.useEffect(() => {
    socket.on("game-on-room-update", (rooms) => {
      if (!rooms) return;

      const setRooms = roomsAtom[1];
      setRooms(rooms);
    });

    socket.on("room-create-room-response", (room) => {
      const setEvent = eventAtomState[1];
      setEvent({ name: "room-create-room-response", response: room });
    });

    return () => {
      socket.off("game-on-room-update");
      socket.off("room-create-room-response");
    };
  }, [roomsAtom, eventAtomState]);

  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.any,
};
