import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const ConfirmationToast = ({
  message,
  type = 'primary',
  duration = 3000,
  onClose,
  onConfirm,
  showConfirmButtons = false,
  title,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!showConfirmButtons) {
      const timer = setTimeout(() => {
        setShow(false);
        if (onClose) onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [duration, onClose, showConfirmButtons]);

  if (!show) return null;

  const getToastClass = () => {
    switch (type) {
      case 'primary':
        return 'custom-toast-primary';
      case 'success':
        return 'custom-toast-success';
      case 'warning':
        return 'custom-toast-warning';
      case 'danger':
        return 'custom-toast-danger';
      default:
        return 'custom-toast-primary';
    }
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
    if (onClose) onClose();
  };

  return (
    <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050 }}>
      <div
        className={`toast show ${getToastClass()}`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <strong className="me-auto">{title}</strong>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
          ></button>
        </div>
        <div className="toast-body px-4">
          {message}
          {showConfirmButtons && (
            <div className="mt-2 pt-2">
              <button
                type="button"
                className="btn btn-success px-4 btn-sm me-2"
                onClick={handleConfirm}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger px-4 btn-sm"
                onClick={handleClose}
              >
                No
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationToast;
