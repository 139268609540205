import React, { useCallback, useContext } from "react";
import Canvas from "./canvas";
import Chat from "./chat";
import "./gameRoom.css";
import "../App.css";
import "./lobby/lobby.css";
import BrushIcon from "../static/brush.png";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useSound from "use-sound";

import ClickonSfx from "../sounds/Clickon.wav";
import Modal from "react-modal";
import { Share, PersonBadge } from "react-bootstrap-icons";
import PlayerDropdown from "./PlayerDropdown";
import { useAtom } from "jotai";
import { userNameAtom } from "../atoms/username.atom";
import { roominfoAtom } from "../atoms/roominfo.atom";
import { SocketContext } from "../SocketProvider";
import PropTypes from "prop-types";
import ConfirmationToast from './ConfirmationToast';
import { createPortal } from 'react-dom';

const doValidation = {
  isCurrentKickedUserIsMoreThanTotalUsersInTheRoom(percentageValue) {
    return percentageValue >= 0.5;
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "50px",
  },
};
const GameRoom = ({ roomID, autoLeave }) => {
  const socket = useContext(SocketContext);
  const [Clickon] = useSound(ClickonSfx);

  const [currentUsername] = useAtom(userNameAtom);
  const userName = currentUsername;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [canvasWidth, setCanvasWidth] = React.useState(0);
  const [roomsInfo] = useAtom(roominfoAtom);
  const roomInfo = roomsInfo[roomID];
  const [sharedUrl] = React.useState("");
  const [isSharedUrlCopied, setIsSharedUrlCopied] = React.useState(false);
  const history = useHistory();
  const [showKickToast, setshowKickToast] = React.useState(false);
  const [kickedPlayer, setKickedPlayer] = React.useState(null);
  const [playerKicker, setplayerKicker] = React.useState(null);

  const handleLeaveRoom = useCallback(() => {
    Clickon();
    socket.emit("leaveRoom", roomInfo);
    if (roomInfo.game.drawer === userName) {
      socket.emit("forceStopTimer");
    }
    history.replace("/");
    history.go();

  }, [Clickon, history, roomInfo, socket, userName]);


  React.useEffect(() => {
    socket.on("game-on-player-request-to-kick", ({ room, player, user }) => {
      const currentRoom = room;
      if (currentRoom.kicks[player.userName] && currentRoom.kicks[player.userName].kickers.includes(currentUsername)) return;

        setKickedPlayer(player);
        setplayerKicker(user);

        if ( currentUsername !== user && currentUsername !== player.userName) {
          setshowKickToast(true);
        }

      if (player.userName === userName) {
        const kickedPercentage = currentRoom.kicks[userName].numberOfRequests / room.currentPlayers;

        if (
          doValidation.isCurrentKickedUserIsMoreThanTotalUsersInTheRoom(
            kickedPercentage
          )
        ) {
          setshowKickToast(false);

          socket.emit("game-on-player-kicked", {
            player,
            roomID: roomInfo.roomID,
          });
          handleLeaveRoom();
          return;
        }
      }
    });
    
    return () => {
      socket.off("game-on-player-request-to-kick");
      socket.off("game-request-vote-kick");
      socket.off("accept-vote-kick");
    };
  }, [socket, userName, handleLeaveRoom, roomInfo]);

  React.useEffect(() => {
    // detect current window width and set to canvas width
    const handleResize = () => {
      const currentWIndowWidth = window.innerWidth;
      const canvasWidth = 0.46 * currentWIndowWidth;
      setCanvasWidth(canvasWidth);
      console.log(canvasWidth);
    };
    handleResize();
    // window.addEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    socket.on("game-on-player-kicked-hide-modal", ({player, roomID}) => {
      if (showKickToast && kickedPlayer.userName === player.userName) {
        setshowKickToast(false);
      }
    })
  }, [socket, showKickToast]);

  const getCurrentURl = React.useCallback(() => {
    return window.location.href;
  }, []);

  const onSharedUrlCopied = () => {
    setIsSharedUrlCopied(true);
    const sharedUrl = getCurrentURl();
    navigator.clipboard.writeText(sharedUrl);
    setTimeout(() => setIsSharedUrlCopied(false), 2000);
  };

  // React.useEffect(() => {
  //   socket.on("newUserJoinRoom", (data) => {
  //     rooms[roomID] = data.roomInfo;
  //     setRooms(rooms);
  //   });
  // }, []);

  // React.useEffect(() => {
  //   socket.on("update-current-room-broadcasted", (data) => {
  //     // setRoomInfo(data);
  //   });
  // }, []);

  // // React.useEffect(() => {
  // //   socket.on("game-on-picking-words", (data) => {
  // //     // setRoomInfo(data);
  // //     // if (data.game.drawer === userName) {
  // //     //   socket.emit("start-picking-word", roomInfo.roomID);
  // //     // }
  // //   });
  // // }, []);

  // React.useEffect(() => {
  //   socket.on("game-on-drawer-start-drawing", (data) => {
  //     // setRoomInfo(data);
  //     if (data.game.drawer === userName) {
  //       socket.emit("game-on-drawing-and-timer-begin", roomInfo.roomID);
  //     }
  //   });
  // }, []);

  // React.useEffect(() => {
  //   socket.on("game-on-user-have-right-answer", (data) => {
  //     success();
  //     // setRoomInfo(data);
  //     if (
  //       data.game.drawer === userName &&
  //       data.game.num_of_right === data.currentPlayers - 1
  //     ) {
  //       socket.emit("finishedTimer", data);
  //     }
  //   });
  // }, []);

  // React.useEffect(() => {
  //   const sharedUrl = getCurrentURl();
  //   setSharedUrl(sharedUrl);
  // }, []);

  React.useEffect(() => {
    if (autoLeave) {
      handleLeaveRoom();
    }
  }, [autoLeave]);

  const votekick = (player) => {
    socket.emit("game-request-vote-kick", player);
    setshowKickToast(false);
  };

  return (
    <div className="room-bg position-relative">
      <div className="room-container flex-column">
        <div className="header-nav blue-bg" style={{ height: "57px" }}>
          <div className="game-room-banner  justify-content-center p-2 d-flex  glass-rect">
            <h5 className="title-font text-title room-banner-title mb-0">
              {roomInfo.roomName} Room
            </h5>
            <h5
              className="title-font text-title mb-0"
              style={{ color: "#fece10" }}
            >
              (ROUND {roomInfo.game.currentRound} / {roomInfo.rounds} -{" "}
              {currentUsername})
            </h5>
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          onAfterOpen={() => { }}
          onRequestClose={() => { }}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}

          <div
            className="share-room blue-box d-flex flex-column justify-content-center align-items-center"
            style={{ gap: "5px" }}
          >
            <span>Share your room</span>
            <span> {sharedUrl} </span>
            {isSharedUrlCopied ? (
              <span>Copied</span>
            ) : (
              <CopyToClipboard text={sharedUrl} onCopy={onSharedUrlCopied}>
                <button className="btn btn-light">Copy to clipboard</button>
              </CopyToClipboard>
            )}
            <button
              type="button"
              className="btn btn-light mt-5"
              onClick={() => {
                setIsModalOpen(false);
                }}
              >
                close
              </button>
              </div>
            </Modal>
            <div className="block-2 row" style={{ height: "calc(100% - 57px)" }}>
              <div className="room-player-list col-2 h-100">
              <h5 className="title-font text-title d-flex justify-content-center align-item-center p-2">
                Player List
              </h5>
              <div className="playerList mp-1">
                <ul className="d-flex flex-column" style={{ gap: "5px" }}>
                {roomInfo.scoreBoard.map((player, index) => {
                  console.log({ player })
                  return (
                  <li key={player.userName} className="flex-start">
                  <PlayerDropdown
                    player={player}
                    socket={socket}
                    className={
                    userName === player.userName ? "player-current" : ""
                    }
                    isOptionDropdownShowed={
                    player.userName !== roomInfo.host &&
                    userName !== player.userName
                    }
                    totalPlayers={roomInfo.currentPlayers}
                  >
                    <div
                    className="d-flex flex-grow-1  flex-row align-items-center"
                    style={{ gap: "5px", overflow: 'hidden', width: '100%' }}
                    >
                    <div className="d-flex flex-row align-items-center " style={{ width: '100%', overflow: 'hidden' }}>
                      {player.userName === roomInfo.host && (
                      <span title="host">
                        <PersonBadge />
                      </span>
                      )}
                      <div className="userBoard">
                      {player.userName}
                      {userName === player.userName}
                      </div>
                    </div>

                    <div className="d-flex flex-row align-items-center " style={{ flex: 'none' }}>
                      {player.userName === roomInfo.game.drawer && (
                      <div className="rank-icon-xs green-icon">
                        <img src={BrushIcon} alt="brush icon" />
                      </div>
                      )}
                      <div className="score" style={{ textAlign: "left" }}>{player.score}</div>
                    </div>
                  
                    </div>
                  </PlayerDropdown>
                  {/* <div
                    className={`player d-flex flex-row ${
                    userName === player.userName ? "player-current" : ""
                    }`}
                  >
                    <div className="username flex">
                    {player.userName}
                    {userName === player.userName && <span>-It's You</span>}
                    {player.userName === roomInfo.host && (
                      <div className="rank-icon-xs margin-left-extra">
                      <img src={HostIcon} alt="host icon" />
                      </div>
                    )}
                    {player.userName === roomInfo.game.drawer && (
                      <div className="rank-icon-xs green-icon margin-left-extra">
                      <img src={BrushIcon} alt="brush icon" />
                      </div>
                    )}
                    </div>
                    <div className="score">{player.score}</div>
                  </div> */}
                  </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="room-canvas col-10 d-flex flex-column">
            <div className="row  blue-box g-0" style={{ flex: 'nowrap' }}>
              {canvasWidth > 0 && (
                <div className="col" style={{ width: canvasWidth + "px" }}>
                  <Canvas
                    roomInfo={roomInfo}
                    userName={userName}
                    socket={socket}
                    canvasParentWidth={canvasWidth}
                  />
                </div>
              )}
              <div className="col h-100" style={{ display: "flex", flexDirection: "column" }}>
                <h5 className="title-font text-title d-flex justify-content-center align-item-center p-2">
                  MESSAGE
                </h5>
                <Chat socket={socket} userName={userName} room={roomsInfo[roomID]} />
              </div>
            </div>
            <div className="row p-3">
              <div className="col">
                <div className="justify-content-between p-2 d-flex ">
                  <button
                    onClick={(e) => handleLeaveRoom()}
                    className="button-yellow"
                    style={{ padding: 0, fontWeight: "bold" }}
                  >
                    Back to Lobby
                  </button>

                  <div>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Share room <Share></Share>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showKickToast && playerKicker !== currentUsername &&
            createPortal(
              <ConfirmationToast
                message={`${playerKicker} initiate vote to kick ${kickedPlayer.userName}, proceed?`}
                showConfirmButtons={true}
                onConfirm={() => votekick({...kickedPlayer, accept: true})}
                onClose={() => setshowKickToast(false)}
                title="Vote to Kick"
              />,
              document.body
            )}
    </div>
  );
};

export default GameRoom;

GameRoom.propTypes = {
  roomID: PropTypes.string,
};
