import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../hooks/query";
import "./login.css";
import Alert from "@material-ui/lab/Alert";
import logo from "../static/tebak-gambar.png";
import useSound from "use-sound";
import ClickonSfx from "../sounds/Clickon.wav";
import { v4 as uuidv4 } from "uuid";
import socketLogin from "../websocket-service/socket-login";
import { useAtom } from "jotai";
import { userNameAtom } from "../atoms/username.atom";
import { SocketContext } from "../SocketProvider";
import PropTypes from "prop-types";
import { checkServerConnection } from "../utils/getConnection"

export default function GuestRegister({ handleAfterRegister }) {
  const socket = useContext(SocketContext)
  let query = useQuery()
  const roomId = query.get('roomId')
  const [Clickon] = useSound(ClickonSfx);
  const [userName, setUserName] = useAtom(userNameAtom);

  const [password, setPassword] = React.useState("");

  const [info, setInfo] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  let history = useHistory();
  function handleReg() {
    Clickon();
    const uuid = uuidv4();
    setPassword(uuid);
    const email = "guest" + uuid + "@tebakgambar.com";
    const data = { userName: userName, password: uuid, email };
    if (userName === "") {
      setInfo("Please Fill your name the Field!");
      return;
    }

    localStorage.setItem("user-email", email);
    socket.emit("register", data);
  }

  function navigateToLoginPage() {
    Clickon();
    if (roomId) {
      history.push(`/room/${roomId}`)
    } else {
      history.push('/login')
    }
  }

  React.useEffect(() => {
    checkServerConnection();
  }, []);

  React.useEffect(() => {
    socket.on("register-response", (data) => {
      if (data === "emailExisting") {
        setInfo("Email Already Exist!");
      } else if (data === "userNameExisting") {
        setInfo("Username Already Exist!");
      } else if (data === "UserAlreadyRegistered") {
        setInfo("User already registered. Not as a guest!");
      } else if (data === "guest-success") {
        const registeredEmail = localStorage.getItem("user-email");
        socketLogin({ socket, flag: registeredEmail, password: password });
      } else {
        navigateToLoginPage();
      }
    });

    return () => {
      socket.off("register-response");
    }
  }, [password]);

  React.useEffect(() => {
    socket.on("loginSuccess", (data) => {
      // handleAfterRegister(data.userName, data.initdata);
      setIsLoading(true);
      setUserName(data.userName)
      if (roomId) {
        window.location.href = `/room/${roomId}`;
      } else {
        window.location.href = "/";
      }
    });

    socket.on("loginFailed", (data) => {
      console.log({ data })
      if (data === "UserNotExists") {
        setInfo("User Not Exist!");
      } else if (data === "alreadyOnline") {
        setInfo("User Already Logged In!");
      } else if (data === "UserAlreadyRegistered") {
        setInfo("User already registered. Not as a guest!");
      }
    })

    return () => {
      socket.off("register-response");
      socket.off("loginSuccess");
      socket.off("loginFailed");
      socket.off("guestLogin");
    }
  }, []);

  if (isLoading) {
    return <div className="" style={{ backgroundColor: "white" }}></div>;
  }
  return (
    <div className="w-100 h-100 row">
      <div className="col">&nbsp;</div>
      <div className="blue-box" style={{ maxWidth: "360px" }}>
        <div className="card--header ">
          <p className="title" style={{ fontSize: "48px", marginTop: "60px" }}>
            Join as a Guest
          </p>
          {info === "" ? <a></a> : <Alert severity="error">{info}</Alert>}
        </div>
        <div className="card--body " style={{ gap: "10px" }}>
          <div className="d-flex flex-column" style={{ gap: "10px" }}>
            <label>Your Name : {userName}</label>
            <input
              type="text"
              className="form-control"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-row" style={{ gap: "10px" }}>
          <button
            onClick={handleReg}
            type="submit"
            id="signup"
            className="btn btn-primary flex-fill"
          >
            Join as a Guest
          </button>
          <button
            onClick={navigateToLoginPage}
            className="btn btn-link  flex-fill"
            style={{ color: "white" }}
          >
            I have an account
          </button>
        </div>
      </div>
    </div>
  );
}

GuestRegister.propTypes = {
  handleAfterRegister: PropTypes.func
}
