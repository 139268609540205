import React, { useCallback, useContext, useState } from "react";
import ChatWindow from "./ChatWindow.js";

import "./styles.css";

import useSound from "use-sound";

import sendSfx from "../sounds/send.wav";
import { SocketContext } from "../SocketProvider.js";
import { userNameAtom } from "../atoms/username.atom.js";
import { useAtom } from "jotai";
import PropTypes from "prop-types";

export default function Chat({ room }) {
  const [newMsg, setNewMsg] = useState("");
  const socket = useContext(SocketContext);
  const [userName] = useAtom(userNameAtom);
  const [sendOn] = useSound(sendSfx);

  function handleCompose(value) {
    setNewMsg(value);
  }

  const handleNewMessage = useCallback(() => {
    sendOn();
    if (newMsg !== "") {
      socket.emit("on-new-message-send", newMsg);
      setNewMsg("");
    }
  }, [newMsg, sendOn, socket]);

  function onKeyup(e) {
    if (e.keyCode === 13) {
      handleNewMessage(newMsg);
    }
  }

  return (
    <div className="" style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <ChatWindow messagesList={room.messages} userName={userName} />
      <div className="chat-composer" style={{ marginTop: 'auto' }}>
        <input
          className="form-control"
          placeholder="Answer here and <Enter>"
          onChange={(e) => handleCompose(e.target.value)}
          value={newMsg}
          style={{ borderRadius: 0, width: "calc(100% + 20px)", transform: "translateY(5px)" }}
          onKeyUp={onKeyup}
        />
      </div>
    </div>
  );
}

Chat.propTypes = {
  room: PropTypes.any
}