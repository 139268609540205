import PropTypes from "prop-types";

const PageWrapper = ({ children }) => {
  return (
    <div className="App">
      <div className="game-header main-background">
      {children}
      </div>
      
    </div>
  );
};

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.any,
};
