import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import "./login.css";
import Alert from "@material-ui/lab/Alert";
import logo from "../static/tebak-gambar.png";
import useSound from "use-sound";
import ClickonSfx from "../sounds/Clickon.wav";
import { SocketContext } from "../SocketProvider";
import socketLogin from "../websocket-service/socket-login";
import { useAtom } from "jotai";
import { userNameAtom } from "../atoms/username.atom";
import { checkServerConnection } from "../utils/getConnection"
import {useQuery} from "../hooks/query";

export default function Register() {
  const socket = useContext(SocketContext)
  let query = useQuery()
  const roomId = query.get('roomId')
  const [Clickon] = useSound(ClickonSfx);
  const [userName, setUsername] = React.useState("");
  const [userNameStorage, setUsernameStorage] = useAtom(userNameAtom);
  const [password, setPassword] = React.useState("");
  const [c_password, setCPassowrd] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [info, setInfo] = React.useState("");
  let history = useHistory();

  function handleReg() {
    Clickon();
    const data = { userName: userName, password: password, email: email };
    if (userName === "" || password === "" || email === "") {
      setInfo("Please Fill All the Field!");
    } else if (password !== c_password) {
      setInfo("Confirm Password Does Not Match the Password!");
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        email
      )
    ) {
      setInfo("Please Enter a Vaild Email Address!");
    }

    if (
      (userName !== "") &
      (password !== "") &
      (email !== "") &
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        email
      )
    ) {
      if (password === c_password) {
        socket.emit("register", data);
      }
    }
  }

  function navigateToLoginPage() {
    Clickon();
    if (roomId) {
      window.location.href = `/room/${roomId}`;
    } else {
      window.location.href = "/login";
    }
  }
  React.useEffect(() => {
    checkServerConnection();
  }, []);

  React.useEffect(() => {
    socket.on("register-response", (data) => {
      if (data === "emailExisting") {
        setInfo("Email Already Exist!");
      } else if (data === "userNameExisting") {
        setInfo("Username Already Exist!");
      } else if (data === "success") {
        socketLogin({ socket, flag: email, password: password });
      }
      else {
        navigateToLoginPage();
      }
    });
    return () => {
      socket.off("register-response");
    }
  }, [email, password]);

  React.useEffect(() => {
    socket.on("loginSuccess", (data) => {
      setUsernameStorage(data.userName)
      setTimeout(() => {
        if (roomId) {
          window.location.href = `/room/${roomId}`;
        } else {
          window.location.href = "/login"
        }
      }, 1200)
    });
    return () => {
      socket.off("loginSuccess");
    }
  })
  return (
    <div className="w-100 h-100 row">
      <div className="col">&nbsp;</div>
      <div className="blue-box" style={{ maxWidth: "360px" }}>
        <div className="card--header ">
          <p className="title" style={{ fontSize: "48px", marginTop: "60px" }}>
            Register
          </p>
          {info === "" ? <a></a> : <Alert severity="error">{info}</Alert>}
        </div>
        <div className="card--body">
          <div>
            <label>Your Name</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div>
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-control"
              onChange={(e) => {
                setCPassowrd(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-row" style={{ gap: "10px" }}>
          <button
            onClick={handleReg}
            type="submit"
            id="signup"
            className="btn btn-primary flex-fill"
          >
            Register Now
          </button>

          <button
            style={{ color: "white" }}
            onClick={navigateToLoginPage}
            className="btn btn-link  flex-fill"
          >
            I have an account
          </button>
        </div>
      </div>
    </div>
  );
}
