const data = {
  words: [
    {
      word: "grape",
      type: "food",
    },
    {
      word: "coconut",
      type: "food",
    },
    {
      word: "strawberry",
      type: "food",
    },
    {
      word: "pineapple",
      type: "food",
    },
    {
      word: "watermelon",
      type: "food",
    },
    {
      word: "orange",
      type: "food",
    },
    {
      word: "noodles",
      type: "food",
    },
    {
      word: "rice",
      type: "food",
    },
    {
      word: "hamburger",
      type: "food",
    },
    {
      word: "soup",
      type: "food",
    },
    {
      word: "lion",
      type: "animal",
    },
    {
      word: "tiger",
      type: "animal",
    },
    {
      word: "giraffe",
      type: "animal",
    },
    {
      word: "leopard",
      type: "animal",
    },
    {
      word: "flamingo",
      type: "animal",
    },
    {
      word: "cat",
      type: "animal",
    },
    {
      word: "crocodile",
      type: "animal",
    },
    {
      word: "snake",
      type: "animal",
    },
    {
      word: "elephant",
      type: "animal",
    },
    {
      word: "panda",
      type: "animal",
    },
    {
      word: "cinema",
      type: "place",
    },
    {
      word: "hospital",
      type: "place",
    },
    {
      word: "factory",
      type: "place",
    },
    {
      word: "school",
      type: "place",
    },
    {
      word: "supermarket",
      type: "place",
    },
    {
      word: "bathroom",
      type: "place",
    },
    {
      word: "library",
      type: "place",
    },
    {
      word: "bedroom",
      type: "place",
    },
    {
      word: "airport",
      type: "place",
    },
    {
      word: "restaurant",
      type: "place",
    },
    {
      word: "aircraft",
      type: "transportation",
    },
    {
      word: "train",
      type: "transportation",
    },
    {
      word: "Ferry",
      type: "transportation",
    },
    {
      word: "bicycle",
      type: "transportation",
    },
    {
      word: "bus",
      type: "transportation",
    },
    {
      word: "car",
      type: "transportation",
    },
    {
      word: "helicopter",
      type: "transportation",
    },
    {
      word: "motorcycle",
      type: "transportation",
    },
    {
      word: "taxi",
      type: "transportation",
    },
    {
      word: "truck",
      type: "transportation",
    },
    {
      word: "quarrel",
      type: "action",
    },
    {
      word: "fight",
      type: "action",
    },
    {
      word: "embrace",
      type: "action",
    },
    {
      word: "smoking",
      type: "action",
    },
    {
      word: "walk",
      type: "action",
    },
    {
      word: "dancing",
      type: "action",
    },
    {
      word: "fitness",
      type: "action",
    },
    {
      word: "drink",
      type: "action",
    },
    {
      word: "sing",
      type: "action",
    },
    {
      word: "boxing",
      type: "action",
    },
  ],
};
export const wordsBank = data;
export const getRandomWords = (size = 3, type = "action") => {
  const pickedWords = wordsBank.words.filter((w) => w.type === type);
  let arr = getRandomNumbers(size, pickedWords.length);
  let randomWords = [];
  arr.forEach((i) => randomWords.push(pickedWords[i].word));
  return randomWords;
};
const getRandomNumbers = (size, length) => {
  let arr = [];
  while (arr.length < size) {
    let r = Math.floor(Math.random() * length);
    if (arr.indexOf(r) === -1) arr.push(r);
  }
  return arr;
};
export default data;
