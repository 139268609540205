import "./App.css";
import React, { useCallback } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { SocketContext, SocketProvider } from "./SocketProvider";
import { Switch, Route, Redirect } from "react-router-dom";
import Lobby from "./components/lobby/lobby";
import PageWrapper from "./PageWrapper";
import RoomPage from "./components/page/RoomPage";
import GuestRegister from "./components/guestRegister";
import Login from "./components/login";
import Register from "./components/register";

function App() {
  const storageUserName = localStorage.getItem("userName");
  
  const isLoggedIn = !!storageUserName

  const handleLogin = useCallback((username, initData) => {}, []);

  
  // React.useEffect(() => {
  //   // socket.on('game-on-room-update', (data) => {   //Listen for "Create Room"
  //   //   console.log({gamonRoomUpdate:data})
  //   //   // setRooms(data)
  //   // })
  // }, []);
 

  return (
    <SocketProvider>
      <PageWrapper>
        <Router>
          <Switch>
            <Route exact path="/">
              {!isLoggedIn ? <Redirect to="/login" /> : <Lobby></Lobby>}
            </Route>
          </Switch>
          <Route exact path="/guest-register">
            <PageWrapper>
              <GuestRegister handleAfterRegister={handleLogin}></GuestRegister>
            </PageWrapper>
          </Route>
          <Route exact path="/register">
            <PageWrapper>
              <Register></Register>
            </PageWrapper>
          </Route>

          <Route exact path="/login">
            <PageWrapper>
              {isLoggedIn ? (
                <Redirect to="/" />
              ) : (
                <Login handleLogin={handleLogin} />
              )}
            </PageWrapper>
          </Route>

          <Route path="/room/:id">
            {!isLoggedIn ? <Login handleLogin={handleLogin} /> : <RoomPage />}
          </Route>
        </Router>
      </PageWrapper>
    </SocketProvider>
  );
}

export default App;
