import React, { useContext, useState } from "react";
import RoomList from "./roomList";
import CreateRoom from "../CreateRoom/CreateRoomModal/CreateRoomModal";
import Statistics from "./statistics";
// import logo from "../../static/tebak-gambar.png";
// import publicRoom from "../../static/publicRoom.png";
// import privateRoom from "../../static/privateRoom.png";
// import allRoom from "../../static/allRoom.png";
import { useHistory } from "react-router-dom";
import "./lobby.css";

import useSound from "use-sound";
import ClickonSfx from "../../sounds/Clickon.wav";
import {
  BroadcastPin,
  DoorOpen,
  DoorClosed,
  Power,
} from "react-bootstrap-icons";

import { useAtom } from "jotai";
import { userNameAtom } from "../../atoms/username.atom";
import { SocketContext } from "../../SocketProvider";
import { allroomsAtom } from "../../atoms/allrooms.atom";
import { gameStatsAtom } from "../../atoms/gamestats.atom";

export default function Lobby() {
  const socket = useContext(SocketContext);
  const [userName] = useAtom(userNameAtom);
  const [rooms, setRooms] = useAtom(allroomsAtom);
  const [Clickon] = useSound(ClickonSfx);
  const [roomType, setRoomType] = useState("All");
  const history = useHistory();
  const [stats, setStats] = useAtom(gameStatsAtom);

  const handleRoomSelection = (roomType) => {
    Clickon();
    setRoomType(roomType);
  };

  function handleCreateRoom(room) {
    Clickon();
    const data = { room: room, userName: userName };
    socket.emit("room-create-room", data);
  }

  function handleJoinRoom(roomID) {
    Clickon();
    const path = "/room/" + roomID;
    history.push(path);
  }

  function handleLogout() {
    socket.emit("user_disconnect", { userName: userName });

    history.go();
    Clickon();
    history.push("/login");
    localStorage.clear();
  }

  React.useEffect(() => {
    socket.on("user_on_connection", (data) => {
      setRooms(data.rooms);
    });

    socket.on("game_stats", (res) => {
      setStats(res);
      socket.off("game_stats")
    })

  }, []);


  return (
    <div className="lobby flex">
      <div className="left flex">
        <div className="left-top glass-blur flex flex-column pixelate">
          <div className="logo-bg lobby-title"></div>
          <div className="account-bg d-flex flex-row" style={{ gap: "10px" }}>
            <div className="pixelate">
              <span> Hello, </span><span className="text-success">{userName}</span>
            </div>
            <button
              className="btn btn-light pixelate text-capitalize"
              onClick={(e) => handleLogout()}
            >
              <Power /> Logout
            </button>
          </div>
          <Statistics data={stats} />
        </div>
        <div
          className="left-btm glass-blur flex flex-column border p-0 border-primary"
          style={{ overflow: "hidden" }}
        >
          <div className="row h-100 gradient ">
            <div
              className="col-8 p-2 d-flex flex-column"
              style={{ gap: "10px" }}
            >
              <div className="room-banner flex">
                <div
                  className="room-type lobby-title h2 pixelate"
                  style={{ color: "white" }}
                >
                  {roomType} Rooms
                </div>
              </div>
              <CreateRoom
                socket={socket}
                handleCreateRoom={handleCreateRoom}
              ></CreateRoom>
              <p>&nbsp;</p>
              <RoomList
                rooms={rooms}
                joinRoom={handleJoinRoom}
                show={roomType}
              ></RoomList>
            </div>
            <div
              className="col-4 "
              style={{
                borderWidth: "0 0 0 3px",
                borderStyle: "solid",
                borderColor:
                  "rgba(var(--bs-primary-rgb), var(--bs-border-opacity))",
                backgroundColor: "white",
              }}
            >
              <div
                className="nav-room  p-2 d-flex flex-column"
                style={{ gap: "10px" }}
              >
                <span className="pixelate h2 font-weight-normal">
                  Find The Rooms
                </span>
                <button
                  className={
                    "btn d-flex align-items-center " +
                    (roomType === "All"
                      ? " btn-primary"
                      : " btn-outline-primary ")
                  }
                  onClick={(_) => handleRoomSelection("All")}
                >
                  <div className="nav-icon">
                    <BroadcastPin />
                  </div>
                  <div className="pixelate">
                    All<span> Rooms</span>
                  </div>
                </button>
                <button
                  className={
                    "btn d-flex align-items-center " +
                    (roomType === "Public"
                      ? " btn-success"
                      : " btn-outline-success ")
                  }
                  onClick={(e) => handleRoomSelection("Public")}
                >
                  <div className="nav-icon">
                    <DoorOpen />
                  </div>
                  <div className="pixelate">
                    Public<span> Rooms</span>
                  </div>
                </button>
                <button
                  className={
                    "btn d-flex align-items-center " +
                    (roomType === "Private"
                      ? " btn-info"
                      : " btn-outline-info ")
                  }
                  onClick={(e) => handleRoomSelection("Private")}
                >
                  <div className="nav-icon">
                    <DoorClosed />
                  </div>
                  <div className="pixelate">
                    Private<span> Rooms</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Lobby.propTypes = {};
