import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "50px",
  },
};

export default function AutokickModal({ isOpen, onResumeCallback, idleCountDown, initialCountdown }) {
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={() => {}}
      onRequestClose={() => {}}
      style={customStyles}
      contentLabel="Iddle state"
    >
      <div>Seems like you're iddle for some minutes </div>
      <div
        className="d-flex flex-row justify-content-center align-items-center"
        style={{ gap: "10px" }}
      >
        <button className="btn btn-primary" onClick={onResumeCallback}>
          resume
        </button>
        <button className="btn btn-outline-dark">
          Auto Kickoff
          {idleCountDown !== initialCountdown && (
            <span> ({idleCountDown})</span>
          )}
        </button>
      </div>
    </Modal>
  );
}
