import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./canvas.css";
import CanvasDraw from "react-canvas-draw";
import { DeleteForever, LineWeight, Palette, Undo } from "@material-ui/icons";
import { Slider } from "@material-ui/core";
import { TwitterPicker } from "react-color";
import React from "react";
import Timer from "./timer";
import WordSelectionMask from "./wordSelectionMask";
import StartGameMask from "./startGameMask";
import LeaderBoardMask from "./leaderBoardMask";
import FinalLeaderBoardMask from "./finalLeaderBoardMask";
import useSound from "use-sound";
import ClickonSfx from "../sounds/Clickon.wav";
import { Clock, Download } from "react-bootstrap-icons";
import PropTypes from "prop-types";
import { SocketContext } from "../SocketProvider";
import { useAtom } from "jotai";
import { timerAtom } from "../atoms/timer.atom";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function Canvas({ roomInfo, userName, canvasParentWidth }) {
  const socket = useContext(SocketContext);
  const colors = [
    "#DB3E00",
    "#FF6900",
    "#ffeb3b",
    "#008B02",
    "#4caf50",
    "#03a9f4",
    "#8ED1FC",
    "#F78DA7",
    "#9900EF",
    "#000000",
  ];
  const DEFAULT_BRUSH_SIZE = 5;
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [showOption, setShowOption] = useState("none");
  const [size, setSize] = useState(0);
  const drawing = "";
  const [brushSize, setBrushSize] = useState(DEFAULT_BRUSH_SIZE);
  const [brushColor, setBrushColor] = useState("#000");
  const [drawingMode, setDrawingMode] = useState("none");
  const randomWords = roomInfo?.game.random_words;
  const [timer, setTimer] = useAtom(timerAtom);
  const [Clickon] = useSound(ClickonSfx);
  const [savedImage, setSavedImage] = useState([]);
  const [isImageStored, setIsImageStored] = useState(false);
  const saveImage = useCallback(() => {
    const canvas = canvasRef.current;
    const isImageWillStored = !isImageStored;

    setIsImageStored((isImageStored) => isImageWillStored);

    if (canvas && isImageWillStored) {
      const jpg = canvas
        .getDataURL("image/jpg")
        .replace("image/jpg", "image/octet-stream");
      setSavedImage((imageList) => {
        return [...imageList, jpg];
      });
    }
  }, [canvasRef]);
  const deboundCanvasChange = function handleCanvasChange() {
    const canvas = canvasRef.current;
    const drawings = canvas.getSaveData();

    if (drawings !== "") {
      if (roomInfo.game.drawer === userName) {
        if (drawingMode === "done") {
          const data = { roomID: roomInfo.roomID, canvas: drawings };
          socket.emit("draw", data);
          setDrawingMode("none");
        }
      }
    }
  };

  React.useEffect(() => {
    socket.on("clearCanvas", () => {
      saveImage();
      const canvas = canvasRef.current;
      if (!canvas) return;

      canvas.clear();
    });
  }, []);

  // React.useEffect(() => {
  //   socket.on("game-on-picking-words", (data) => {
  //     setGameData(data)
  //     console.log({ game: data });
  //     // if (data.game.drawer === userName) {
  //     //   setRandomWords(
  //     //     getRandomWords(
  //     //       3,
  //     //       words.filter((w) => w.type === data.game.wordsType)
  //     //     )
  //     //   );
  //     // }
  //   });
  // }, []);

  React.useEffect(() => {
    socket.on("onDraw", (data) => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      canvas.loadSaveData(data.game.canvas, true);
    });
  }, []);

  React.useEffect(() => {
    const canvasContainer = document.querySelector(".canvas-container");
    if (!canvasContainer) return;
    canvasContainer.addEventListener("mouseup", stopDrawing);
    canvasContainer.addEventListener("touchend", stopDrawing);
  }, []);

  const stopDrawing = () => {
    Clickon();
    if (drawingMode === "none") {
      setDrawingMode("done");
    }
  };

  const handleClear = () => {
    Clickon();
    const canvas = canvasRef.current;

    if (!canvas) return;

    canvas.clear();
  };

  const handleUndo = () => {
    Clickon();
    const canvas = canvasRef.current;

    if (!canvas) return;

    canvas.undo();
  };

  const handleStrokeSizeToggle = () => {
    Clickon();
    if (showOption !== "size") {
      setShowOption("size");
    } else {
      setShowOption("none");
    }

    const sizeBtn = document.querySelector(".size-btn");
    sizeBtn.classList.toggle("btn-active");
  };

  const handleStrokeColorToggle = () => {
    Clickon();
    if (showOption !== "color") {
      setShowOption("color");
    } else {
      setShowOption("none");
    }

    const colorBtn = document.querySelector(".color-btn");
    colorBtn.classList.toggle("btn-active");
  };

  const handleSliderChange = (event, newValue) => {
    Clickon();
    setBrushSize(newValue);
  };

  const handleSliderChangeCommitted = (event, newValue) => {
    Clickon();
    setBrushSize(newValue);
    setShowOption("none");

    const sizeBtn = document.querySelector(".size-btn");
    sizeBtn.classList.remove("btn-active");
  };

  const handleColorChangeComplete = (color, event) => {
    Clickon();
    setBrushColor(color.hex);
    setShowOption("none");

    const colorBtn = document.querySelector(".color-btn");
    colorBtn.classList.remove("btn-active");
  };

  function handleSelectWord(word) {
    Clickon();
    const data = { word: word, roomID: roomInfo.roomID };
    socket.emit("game-on-word-selected", data);
    if (timer !== null) {
      setTimer(30);
    }
  }

  const handleStartGame = () => {
    Clickon();
    const data = { roomID: roomInfo.roomID, userName: userName };

    socket.emit("game-on-game-start", data);
  };


  const maskWord = (word) => {
    return word.replace(/\S/gi, "_ ");
  };

  const debouncedHandleResize = debounce(function handleResize() {
    Clickon();
    if (!canvasRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;
    const length =
      containerHeight < containerWidth ? containerHeight : containerWidth;
    setSize(length);

    const canvas = canvasRef.current;
    let draw = () => {
      if (drawing !== "") {
        canvas.loadSaveData(drawing, true);
      }
    };
    draw();
  }, 100);

  useEffect(() => {
    debouncedHandleResize();

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <>
      <div className="canvas-wrapper d-flex flex-row">
        <div
          className="canvas-container col d-flex flex-column"
          ref={containerRef}
        >
          {(() => {
            const isDrawer = roomInfo.game.drawer === userName;
            const isHost = roomInfo.host === userName;

            if (roomInfo.globalStatus === "waiting") {
              return (
                <StartGameMask
                  isHost={isHost}
                  onStartGame={handleStartGame}
                  currentPlayers={roomInfo.currentPlayers}
                ></StartGameMask>
              );
            } else if (roomInfo.globalStatus === "playing") {
              if (roomInfo.game.status === "on-picking-words") {
                return (
                  (
                    <WordSelectionMask
                      drawer={roomInfo.game.drawer}
                      isDrawer={isDrawer}
                      words={randomWords}
                      onSelectWord={handleSelectWord}
                      socket={socket}
                    >
                    </WordSelectionMask>
                  )
                );
              } else if (roomInfo.game.status === "drawing") {
                return (
                  <div className="canvas-header glass-rect">
                    <span>
                      {isDrawer
                        ? <>You're drawing: <i>{roomInfo.game.word}</i></>
                        : <>The drawing is: <i>{maskWord(roomInfo.game.word)}</i></>
                      }
                    </span>
                    <Timer socket={socket} />
                  </div>
                );
              } else {
                return <LeaderBoardMask players={roomInfo.scoreBoard} />;
              }
            } else {
              return (
                <FinalLeaderBoardMask
                  players={roomInfo.scoreBoard}
                  isHost={isHost}
                  handleStartGame={handleStartGame}
                />
              );
            }
          })()}
          {(() => {
            const isDrawer = roomInfo.game.drawer === userName;
            if (roomInfo.globalStatus === "playing" && isDrawer) {
              return (
                <div className="position-relative" id="canvas-drawer">
                  <CanvasDraw
                    lazyRadius={0}
                    brushColor={brushColor}
                    brushRadius={brushSize}
                    canvasWidth={canvasParentWidth}
                    canvasHeight={size}
                    ref={canvasRef}
                    disabled={false}
                    onChange={deboundCanvasChange}
                  />
                  <div className="tools-overlay flex-center-all">
                    {showOption !== "none" && (
                      <div className="tools-container flex bottom-offset glass-rect-bright rounded-rect">
                        {showOption === "size" && (
                          <div className="size-options">
                            <Slider
                              value={brushSize}
                              aria-labelledby="discrete-slider"
                              valueLabelDisplay="on"
                              marks
                              step={1}
                              min={1}
                              max={20}
                              onChange={handleSliderChange}
                              onChangeCommitted={handleSliderChangeCommitted}
                            />
                          </div>
                        )}
                        {showOption === "color" && (
                          <div className="color-options">
                            <TwitterPicker
                              colors={colors}
                              onChangeComplete={handleColorChangeComplete}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    <div className="tools-container flex bottom glass-rect-bright rounded-rect">
                      <button onClick={saveImage}> capture </button>
                      <div
                        className="tool flex-center-all"
                        onClick={handleStrokeColorToggle}
                      >
                        <div className="tool-btn flex-center-all color-btn">
                          <Palette style={{ color: brushColor }} />
                        </div>
                        <span style={{ color: "black" }}>Color</span>
                      </div>
                      <div
                        className="tool flex-center-all"
                        onClick={handleStrokeSizeToggle}
                      >
                        <div className="tool-btn flex-center-all size-btn">
                          <LineWeight />
                        </div>
                        <span style={{ color: "black" }}>Size</span>
                      </div>
                      <div
                        className="tool flex-center-all"
                        onClick={handleUndo}
                      >
                        <div className="tool-btn flex-center-all">
                          <Undo />
                        </div>
                        <span style={{ color: "black" }}>Undo</span>
                      </div>
                      <div
                        className="tool flex-center-all"
                        onClick={handleClear}
                      >
                        <div className="tool-btn flex-center-all">
                          <DeleteForever />
                        </div>
                        <span style={{ color: "black" }}>Clear</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  <CanvasDraw
                    lazyRadius={0}
                    brushColor={brushColor}
                    brushRadius={brushSize}
                    canvasWidth={canvasParentWidth}
                    canvasHeight={480}
                    ref={canvasRef}
                    disabled={true}
                    onChange={deboundCanvasChange}
                    hideInterface={true}
                  />
                  <button onClick={saveImage}> capture </button>
                </>
              );
            }
          })()}
        </div>
      </div>
      {savedImage.length > 0 && (
        <div
          className="image-histories p-1 d-flex"
          style={{ gap: "5px" }}
        >
          <strong style={{ color: "white" }}>
            {" "}
            <Clock />{" "}
          </strong>
          {savedImage.map((image, index) => (
            <div className="image-histories-item d-flex flex-column">
              <img
                key={index}
                src={image}
                className="p-0"
                alt="last drawing"
                style={{ width: "auto", height: "50px" }}
              />
              <span>
                <a href={image} rel="" download={"image.png"}>
                  <Download />
                </a>
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

Canvas.propTypes = {
  socket: PropTypes.any,
  userName: PropTypes.any,
  roomInfo: PropTypes.any,
  canvasParentWidth: PropTypes.number,
};
export default Canvas;
