import React, { useCallback, useContext, useState } from "react";
import { useHistory, useLocation, useParams, Redirect } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import "./login.css";
import "../App.css";
import logo from "../static/tebak-gambar.png";
import useSound from "use-sound";
import ClickOn from "../sounds/Clickon.wav";
import socketLogin from "../websocket-service/socket-login";
import _ from "lodash";
import Modal from "react-modal";
import { useAtom } from "jotai";
import { userNameAtom } from "../atoms/username.atom";
import { SocketContext } from "../SocketProvider";
import PropTypes from "prop-types";

export default function Login({ handleLogin }) {
  const socket = useContext(SocketContext);
  const { id: room } = useParams()
  const [roomId, setRoomId] = useState(room)
  const [ClickSfxOn] = useSound(ClickOn);
  const [password, setPassword] = React.useState("");
  const [username, setUsername] = useAtom(userNameAtom);
  const [emailUserName, setEmail] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLoginPanelOpened, setIsLoginPanelOpened] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [info, setInfo] = React.useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "50px",
    },
  };

  let history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const loginByGoogle = useCallback(() => {
    if (roomId) {
      console.log('on klik', roomId)
      window.open(`https://server.drawnguess.my.id/auth/google?roomId=${roomId}`, "_self");
    } else {
      window.open("https://server.drawnguess.my.id/auth/google", "_self");
    }
  });
  const loginByFacebook = useCallback(() => {
    if (roomId) {
      window.open(`https://server.drawnguess.my.id/auth/facebook?roomId=${roomId}`, "_self");
    } else {
      window.open("https://server.drawnguess.my.id/auth/facebook", "_self");
    }
  });
  const loginByTwitter = useCallback(() => {
    if (roomId) {
      window.open(`https://server.drawnguess.my.id/auth/twitter?roomId=${roomId}`, "_self");
    } else {
      window.open("https://server.drawnguess.my.id/auth/twitter", "_self");
    }
  });

  function handleLoginButton() {
    ClickSfxOn();
    if (password === "" || emailUserName === "") {
      setInfo("Please Enter All Your Login Information!");
    } else {
      socketLogin({ socket, flag: emailUserName, password: password });
    }
  }

  function openModal() {
    setIsModalOpen(true);
    setIsLoginPanelOpened(false);
  }
  function navigateToRegistration() {
    ClickSfxOn();
    
    {roomId ?
        history.push(`/register?roomId=${roomId}`) : history.push(`/register`)
    }
  }
  function navigateToGuestRegistration() {
    ClickSfxOn();
    {roomId ?
        history.push(`/guest-register?roomId=${roomId}`) : history.push(`/guest-register`)
    }
  }

  React.useEffect(() => {
    const token = params.get("token");
    const hasRoomId = params.get('roomId');
    if (!_.isEmpty(token)) {
      //decode base 64 decode of token
      const decoded = Buffer.from(token, "base64").toString("ascii");

      if (hasRoomId) {
        setRoomId(hasRoomId)
        console.log({ hasRoomId })
      }

      const { email, username, otptoken } = JSON.parse(decoded);
      socketLogin({ socket, flag: email, password: otptoken });
    }
  }, [location]);

  React.useEffect(() => {
    socket.on("loginFailed", (data) => {
      if (data === "UserNotExists") {
        setInfo("User Not Exists!");
      } else if (data === "passwordInvalid") {
        setInfo("Password Invalid!");
      } else if (data === "alreadyOnline") {
        setInfo("Your Account Is Already Online!");
      }
      setIsLoginPanelOpened(true);
    });
  }, []);

  React.useEffect(() => {
    socket.on("loginSuccess", (data) => {
      console.log('login sukses bang!', roomId)
      setIsLoading(true);
      setUsername(data.userName);
      if (roomId) {
        window.location.href = `/room/${roomId}`;
      } else {
        window.location.href = "/";
      }
    });
  }, [roomId]);

  if (isLoading) {
    return <div className="" style={{ backgroundColor: "white" }}></div>;
  }
  return (
    <div className="w-100 h-100 row overflow-hidden">
      <div className="col ">
        <div
          className="w-100 h-100 d-flex flex-column justify-content-center align-items-center pixelate"
          style={{ transform: "scale(2.5)" }}
        >
          <div className="gradientBlueBoxTransparent p-3 ">
            <h1 className="" style={{ color: "white" }}>
              Tebak <span className="text-info h2">Gambar</span>
            </h1>
            <button className=" button-yellow" onClick={openModal}>
              Ayo Main
            </button>
          </div>
          <Modal
            isOpen={isModalOpen}
            onAfterOpen={() => { }}
            onRequestClose={() => {
              setIsModalOpen(false);
            }}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center  p-3 blue-box "
              style={{ minHeight: "240px", minWidth: "480px" }}
            >
              <div
                className="d-flex flex-row justif-content-center p-3"
                style={{ gap: "10px", fontSize: "20px", minWidth: "480px" }}
              >
                <button
                  className="blue-box-fill"
                  style={{ gap: "10px", fontSize: "20px", minWidth: "180px" }}
                  onClick={() => {
                    setIsLoginPanelOpened(true);
                    setIsModalOpen(false);
                  }}
                >
                  Login
                </button>
                <button
                  className="button-yellow"
                  style={{ gap: "10px", fontSize: "20px", minWidth: "180px" }}
                  onClick={navigateToRegistration}
                >
                  New member
                </button>
                <button
                  className="button-green"
                  style={{ gap: "10px", fontSize: "20px", minWidth: "180px" }}
                  onClick={navigateToGuestRegistration}
                >
                  As Guest
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      {isLoginPanelOpened && (
        <div
          className=" login-page col blue-box "
          style={{ maxWidth: "360px", overflowY: "auto", maxHeight: "100vh" }}
        >
          <div className="card--header ">
            <p
              className="title"
              style={{ fontSize: "48px", marginTop: "60px" }}
            >
              Login
            </p>
            {info === "" ? "" : <Alert severity="error">{info}</Alert>}
          </div>
          <div
            className="card--body d-flex flex-column"
            style={{ gap: "10px" }}
          >
            <div className="d-flex flex-column" style={{ gap: "10px" }}>
              <label>Your email/username</label>
              <input
                className="form-control"
                value={emailUserName}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label>Password</label>
              <input
                className="form-control"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div style={{ gap: "10px" }} className="d-flex flex-column">
            <div className="d-flex flex-row" style={{ gap: "10px" }}>
              <button
                type="submit"
                id="signup"
                className="btn btn-primary flex-fill"
                onClick={handleLoginButton}
              >
                Login
              </button>
            </div>
            <hr className="w-100" />

            <div className="d-flex flex-row" style={{ gap: "10px" }}>
              <button
                onClick={loginByGoogle}
                className="btn btn-danger  flex-fill"
              >
                Google Login
              </button>
            </div>

            <div className="d-flex flex-row" style={{ gap: "10px" }}>
              <button
                onClick={loginByFacebook}
                className="btn btn-primary  flex-fill"
              >
                Facebook Login
              </button>
            </div>

            <div className="d-flex flex-row" style={{ gap: "10px" }}>
              <button
                onClick={loginByTwitter}
                className="btn btn-light  flex-fill"
              >
                Twitter Login
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Login.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};
