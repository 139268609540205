import React from 'react';
import PublicRoomCard from './publicRoom';
import PrivateRoomCard from './privateRoom';
import './roomList.css';
import PropTypes from "prop-types";
export default function RoomList({ rooms, joinRoom, show }) {
  let publicRooms = []
  let privateRooms = []
  for (const room of rooms.entries()) {
    const value = room[1];
    if (value.roomType === "Public") {
      publicRooms.push(value)
    } else if (value.roomType === "Private") {
      privateRooms.push(value)
    }
  }

  return (
    <div className="room-list d-flex flex-column" style={{gap: "10px"}}>
      {show !== "Private" &&
        <ul>
          {publicRooms.map((room, index) =>
            <button style={{width: "100%", border: "none", background: "none"}} key={room.roomID} tabIndex={index} onClick={() => joinRoom(room.roomID)}>
              <PublicRoomCard room={room}></PublicRoomCard>
            </button>
          )}
        </ul>
      }
      {show !== "Public" &&
        <ul>
          {privateRooms.map((room) =>
            <PrivateRoomCard room={room} joinRoom={joinRoom}></PrivateRoomCard>
          )}
        </ul>
      }
    </div>
  );
}

RoomList.propTypes = {
  rooms: PropTypes.array,
  joinRoom: PropTypes.any,
  show: PropTypes.any
}