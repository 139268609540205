import React from "react";
import "./chat.css";

export default function ChatWindow({ messagesList, userName }) {
  const messagesEndRef = React.useRef(null);

  const scrollToBottom = () => {
    const element = document.getElementById('chatbox')
    if (!element) return;
    element.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: 'smooth'
    })
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messagesList]);

  return (
    <div>
      <div id="chatbox" ref={messagesEndRef} className="messages d-flex flex-column" style={{ gap: "5px", overflowY: 'auto', maxHeight: '60vh' }}>
        {Array.isArray(messagesList) &&
          messagesList.map((message, index) => {
            if (message.type === "info") {
              return (
                <p key={index} className="info-in msg-margin">
                  {message.content}
                </p>
              );
            } else if (message.type === "in") {
              return (
                <p key={index} className="info-in msg-margin">
                  {message.user} has entered the room
                </p>
              );
            } else if (message.type === "out") {
              return (
                <p key={index} className="info-out msg-margin">
                  {message.user} has left game
                </p>
              );
            } else if (message.type === "ans") {
              return (
                <p key={index} className="answer msg-margin">
                  {message.user} got the right answer!
                </p>
              );
            } else if (message.type === "player-kick-request") {
              return (
                <p key={index} className="info-in msg-margin" style={{ background: 'orange', color: 'white' }}>
                  player {message.user} has requested to kick <span style={{ color: 'red'}}>{message.target}</span>
                </p>
              );
            } else if (message.type === "player-kick-request-accepted") {
              return (
                <p key={index} className="info-in msg-margin" >
                  player {message.user} has accepted to kick <span style={{ color: 'orange'}}>{message.target}</span>
                </p>
              );
            } else if (message.type === "player-kicked") {
              return (
                <p key={index} className="info-out msg-margin" style={{ background: 'orange', color: 'red' }}>
                  player {message.target} has been kicked
                </p>
              );
            } else {
              return (
                <p
                  key={index}
                  className={
                    "message msg-margin d-flex " +
                    (message.user === userName ? " you align-items-end" : "")
                  }
                >
                  <div className="user" style={{ color: "white" }}>
                    {message.user === userName ? "You" : message.user}
                  </div>
                  <div className="text">{message.text}</div>
                </p>
              );
            }
          })}
      </div>
    </div>
  );
}
