import Dropdown from "react-bootstrap/Dropdown";
import { ThreeDotsVertical } from "react-bootstrap-icons";

function PlayerDropdown({
  children,
  className,
  player,
  isOptionDropdownShowed,
  socket,
  totalPlayers,
}) {
  const onVoteKick = () => {
    if (totalPlayers < 3) {
      alert("You need at least 3 players to vote kick");
      return;
    }
    socket.emit("game-request-vote-kick", player);
  };
  if (totalPlayers < 3) {
    isOptionDropdownShowed = false;
  }
  return (
    <div className={`player d-flex flex-row p-1 ${className}`}>
      {children}
      {isOptionDropdownShowed && (
        <div style={{ width: "30px" }}>
          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <div>
                <ThreeDotsVertical />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={onVoteKick}>vote kick</Dropdown.Item>
            
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
}

export default PlayerDropdown;
