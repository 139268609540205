import React from "react";
// import useSound from "use-sound";
// import timeSfx from "../sounds/time.wav";
import { useAtom } from "jotai";
import { timerAtom } from "../atoms/timer.atom";

export default function Timer() {
  // const [count, setCount] = React.useState(60);
  // const [time] = useSound(timeSfx);
  const [timer] = useAtom(timerAtom);

  return (
    <>{ <div className="canvas-timer flex-center-all">{timer}</div>}</>
  );
}
