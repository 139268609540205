import axios from "axios";
export const checkServerConnection = async () => {
    try {
        await axios.get("https://server.drawnguess.my.id/connection");

    } catch (error) {
        localStorage.clear();
        window.location.href = "/login";
    }

}