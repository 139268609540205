import React, { useCallback, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import "./CreateRoomModal.css";

import useSound from "use-sound";
import ClickonSfx from "../../../sounds/Clickon.wav";
import WORDS_TYPE from "../../words-type";

import { PlusCircle } from "react-bootstrap-icons";
import { SocketContext } from "../../../SocketProvider";
import { userNameAtom } from "../../../atoms/username.atom";
import { useAtom } from "jotai";
import { eventAtom } from "../../../atoms/event.atom";
import { useHistory } from "react-router-dom";

export default function CreateRoom() {
  const [Clickon] = useSound(ClickonSfx);
  const [userName] = useAtom(userNameAtom);
  const [eventResponse] = useAtom(eventAtom);
  const socket = useContext(SocketContext);
  const history = useHistory();

  const handleCreateRoom = useCallback(
    (room) => {
      Clickon();
      const data = { room: room, userName: userName };
      socket.emit("room-create-room", data);
    },
    [Clickon, socket, userName]
  );

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    Clickon();
    setOpen(true);
  };

  const handleClose = () => {
    Clickon();
    setOpen(false);
    setAlert(false);
    setAlertPass(false);
  };

  const playersMarks = [
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  const roundsMarks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  const [type, setType] = React.useState("Public");

  const handleChange = (event) => {
    Clickon();
    setType(event.target.value);
  };

  const [roomName, setRoomName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [maxPlayers, setMaxPlayers] = React.useState(5);
  const [rounds, setRounds] = React.useState(3);
  const [alert, setAlert] = React.useState(false);
  const [alertPass, setAlertPass] = React.useState(false);
  const [wordsType, setWordsType] = React.useState(WORDS_TYPE[0]);

  function handleMaxPlayersChange(value, newValue) {
    setMaxPlayers(newValue);
  }

  function handleRoundsChange(value, newValue) {
    setRounds(newValue);
  }

  const handleWordsTypeChange = React.useCallback(
    (event) => {
      setWordsType(event.target.value);
    },
    [setWordsType]
  );

  const handleSubmit = useCallback(() => {
    const room = {
      roomName: roomName,
      roomType: type,
      password: password,
      maxPlayers: maxPlayers,
      rounds: rounds,
      wordsType: wordsType,
    };

    if (type === "Public") {
      if (roomName === "") {
        setAlert(true);
      } else {
        setAlert(false);
        handleCreateRoom(room);
      }
    } else if (type === "Private") {
      if (roomName === "" && password === "") {
        setAlert(true);
        setAlertPass(true);
      } else if (password === "") {
        setAlertPass(true);
      } else if (roomName === "") {
        setAlert(true);
      } else {
        setAlert(false);
        setAlertPass(false);
        handleCreateRoom(room);
      }
    }
  }, [
    handleCreateRoom,
    type,
    roomName,
    password,
    maxPlayers,
    rounds,
    wordsType,
  ]);

  React.useEffect(() => {
    if (eventResponse.name === "room-create-room-response") {
      history.push('/room/' + eventResponse.response.roomID)
    }
  }, [eventResponse, history]);
  return (
    <div className="flex-center-all">
      <button
        className="btn btn-primary pixelate d-flex flex-row align-items-center"
        style={{ gap: "5px" }}
        onClick={handleClickOpen}
      >
        <PlusCircle />
        <div className="btn-bg">Create New Room</div>
      </button>
      <Dialog
        className="create-room-modal pixelate"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ fontSize: "32px" }}>Create Room</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a room and invite your friends to play!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Room Name"
            type="string"
            variant="filled"
            fullWidth
            onChange={(e) => setRoomName(e.target.value)}
          />
          <Collapse in={alert}>
            <Alert severity="error">Room name cannot leave empty!</Alert>
          </Collapse>
          <FormLabel component="legend" className="margin_top">
            Room Type
          </FormLabel>
          <RadioGroup
            aria-label="Room Type"
            name="room_type"
            value={type}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Public"
              control={<Radio />}
              label="Public"
            />
            <FormControlLabel
              value="Private"
              control={<Radio />}
              label="Private"
            />
          </RadioGroup>

          {type === "Private" ? (
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="string"
              variant="filled"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
          ) : (
            ""
          )}
          {type === "Private" ? (
            <Collapse in={alertPass}>
              <Alert severity="error">
                Password cannot leave empty in Private mode!
              </Alert>
            </Collapse>
          ) : (
            ""
          )}

          <FormLabel component="legend" className="margin_top">
            Words Category
          </FormLabel>
          <Select
            value={wordsType}
            onChange={handleWordsTypeChange}
            className="w-100 text-capitalize"
          >
            {WORDS_TYPE.map((wType) => (
              <MenuItem
                key={wType}
                className="text-capitalize  p-1"
                value={wType}
              >
                {wType}
              </MenuItem>
            ))}
          </Select>

          <FormLabel component="legend" className="margin_top">
            Max Players
          </FormLabel>
          <Slider
            value={maxPlayers}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-always"
            step={1}
            marks={playersMarks}
            valueLabelDisplay="on"
            min={2}
            max={10}
            className="scroll_bar_margin"
            onChange={handleMaxPlayersChange}
          />

          <FormLabel component="legend" className="margin_top">
            Rounds
          </FormLabel>
          <Slider
            value={rounds}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-always"
            step={1}
            marks={roundsMarks}
            valueLabelDisplay="on"
            min={1}
            max={5}
            className="scroll_bar_margin"
            onChange={handleRoundsChange}
          />
        </DialogContent>
        <div className="d-flex flex-row w-100 p-2" style={{ gap: "10px" }}>
          <div className="col">
            <button
              onClick={handleClose}
              className="btn btn-outline-secondary w-100"
            >
              Cancel
            </button>
          </div>
          <div className="col">
            <button
              className="btn btn-primary  w-100 "
              onClick={() => handleSubmit()}
              color="primary"
            >
              Create
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
